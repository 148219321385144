import { axios } from "./../plugins/axios";

/**
 * @method 获取购卡记录
 * @param {Number} card_type card_type=1 1-包月 2-包季 3-包年
 */
export function getPurchaseRecord(parameter) {
  return axios.request({
    url: "",
    method: "get",
    params: {
      module: "fans",
      action: "card",
      app: "buy_record",
      ...parameter
    }
  });
}
/**
 * @method 获取会员卡数量计算
 */
export function numberOfCards() {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "card",
      app: "card_count"
    }
  });
}

/**
 * @method 会员卡明细 兑换明细
 * @param {Number} card_type 会员卡类型 1-包月 2-包季 3-包年
 */
export function membershipCardDetails(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "card",
      app: "card_list",
      ...parameter
    }
  });
}
/**
 * @method 获取收入总结
 */
export function incomeSummary() {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "get_profit"
    }
  });
}
export function getWxConfig(url) {
  return axios.request({
    params: {
      module: "fans",
      action: "share",
      app: "wechat_open_share",
      key: "d9a113623a5dfaae4639266f176dfda5",
      signUrl: url
    }
  });
}

export function getMoney(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "profit",
      app: "channel_profit",
      store_id: 1,
      ...parameter
    }
  });
}
